import NETWORKS from "configs/networks.json";
import { ethInstance, fromWei, getInterface, toBN, toWei } from "evm-chain-scripts";
import moment from "moment";
import multihashes from "multihashes";
import specialPools from "specialPools.json";
import Krill from "../assets/krill.png";
import Orca from "../assets/orca.png";
import Reef from "../assets/reef.png";
import Buildings from "../assets/resident.svg";
import Seaweed from "../assets/seaweed.png";
import Tycoon from "../assets/tycoon.png";
import BasicPoolFactory from "../truffle/build/contracts/BasicPoolFactory.json";
import LPToken from "../truffle/build/contracts/DummyLPToken.json";
import Resident from "../truffle/build/contracts/LiquidityFactory.json";
import MostBasicYield from "../truffle/build/contracts/MostBasicYield.json";
import Multicall from "../truffle/build/contracts/Multicall.json";
import PermissionlessBasicPoolFactory from "../truffle/build/contracts/PermissionlessBasicPoolFactory.json";
import PermissionlessLiquidityFactory from "../truffle/build/contracts/PermissionlessLiquidityFactory.json";
import Token from "../truffle/build/contracts/Token.json";
import { getAllEvents } from "./eventSub";
import { getDepositToken, getWriteContractByType } from "./helper";

export const secondsPerDay = 86400;
export const secondsPerYear = 31536000;
export const maxPositions = 1;

export const getChainSettings = (chainId) => {
  const defaultAvgTime = 13.1;
  if (chainId) {
    const { avgBlockTime } = NETWORKS[chainId];

    return {
      avgBlockTime: avgBlockTime ?? defaultAvgTime
    };
  } else {
    return defaultAvgTime;
  }
};

export const contracts = {
  MostBasicYield,
  Token,
  BasicPoolFactory,
  Resident,
  LPToken,
  Multicall,
  PermissionlessBasicPoolFactory,
  PermissionlessLiquidityFactory
};

export const widgetImages = {
  Krill,
  Reef,
  Seaweed,
  Orca,
  Buildings,
  Tycoon
};

export const RESULT_TYPES = {
  OBJECT: "object",
  ARRAY: "array"
};

export const TRANSACTION_STATUS = {
  FAILED: 0,
  CONFIRMED: 1
};

export const POOL_TYPES = {
  BASIC: 1,
  RESIDENT: 2
};

export const BASIC_CONTRACT_TYPE = {
  BASIC: "basic",
  PERMISSIONLESS: "permissionless"
};

export async function getRewardTokenPerLPToken(depositTokenAddress, rewardTokenAddress) {
  const contract = await ethInstance.getReadContractByAddress(
    contracts.LPToken,
    depositTokenAddress
  );
  const [token0, token1] = await Promise.all([contract.token0(), contract.token1()]);

  let reserveIndex = 0;

  if (token1.toLowerCase() === rewardTokenAddress.toLowerCase()) {
    reserveIndex = 1;
  } else if (token0.toLowerCase() !== rewardTokenAddress.toLowerCase()) {
    console.error(
      "Cannot convert LP tokens to reward token",
      token0,
      token1,
      depositTokenAddress,
      rewardTokenAddress
    );
    return toBN(0);
  }

  const [reserves, totalSupply] = await Promise.all([
    contract.getReserves(),
    contract.totalSupply()
  ]);

  return toBN(reserves[reserveIndex])
    .mul(toBN(toWei("2")))
    .div(toBN(totalSupply));
}

export async function calcBasicFromTime(pool, startTime, rewardData, end = null, lpToken = false) {
  const endTime = end || pool.endTime;
  const seconds = endTime - startTime;
  const tokensPerSecondPerToken = fromWei(rewardData && rewardData[0] ? rewardData[0][0] : "0");

  let tokensPerToken = tokensPerSecondPerToken * seconds * 100;
  if (lpToken) {
    const rewardsPerLP = await getRewardTokenPerLPToken(pool.depositToken, rewardData[2][0]);
    tokensPerToken /= fromWei(rewardsPerLP.toString());
  }

  return tokensPerToken.toString();
}

export async function get24hrsVolume(pool, _poolIndex, positions) {
  const currentBlock = (await ethInstance.getBlock("latest")).number;
  const { avgBlockTime } = getChainSettings(pool.chainId);
  const blocksPerDay = Math.floor(secondsPerDay / avgBlockTime);
  const _fromBlock = Math.floor(currentBlock - blocksPerDay);

  const last24hPositions = positions.filter((position) => position.blockNumber >= _fromBlock);
  const volume = last24hPositions.reduce((prev, next) => prev + next.deposit, 0);
  return volume;
}

export async function getTokenSymbol(tokenAddress) {
  const contract = await ethInstance.getReadContractByAddress(contracts.Token, tokenAddress);
  const symbol = await contract.symbol();
  return symbol;
}

export async function accountConnected() {
  let account = "";
  try {
    account = await ethInstance.getEthAccount(false);
  } catch (error) {
    console.warn("Account is not connected!");
  }

  return { account: account, isConnected: account.length > 0 };
}

const detectTrueFalse = (value) => {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else {
    return value.toString();
  }
};

function parseEventArgs(args) {
  const argsKeys = Object.keys(args);
  const argsObj = {};
  for (const key of argsKeys) {
    let eventArgValue = args[key];
    if (eventArgValue._isBigNumber === true) {
      eventArgValue = eventArgValue.toString();
    }
    argsObj[key] = eventArgValue;
  }
  return argsObj;
}

export function parseBNData(decodedResponse, resultType, nested = false) {
  let result = resultType === "object" ? {} : [];
  for (const key in decodedResponse) {
    if (resultType === "object") {
      result = {
        ...result,
        [key]: !nested
          ? parseBNData(decodedResponse[key], resultType, true)
          : detectTrueFalse(decodedResponse[key].toString())
      };
    } else {
      result.push(
        !nested
          ? parseBNData(decodedResponse[key], resultType, true)
          : detectTrueFalse(decodedResponse[key].toString())
      );
    }
  }
  return result;
}

function buf2hex(buffer) {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)].map((x) => x.toString(16).padStart(2, "0")).join("");
}

export const getBytes = function (ipfsHash) {
  const bytes = "0x" + buf2hex(multihashes.fromB58String(ipfsHash)).slice(4);
  return bytes;
};

export async function getPositionsDataMulti(events, poolInstance, functionName) {
  try {
    const { chainId } = poolInstance;
    const jsonContract = poolInstance.contract;
    const jsonAbi = poolInstance.contract.abi;

    const address = jsonContract.networks[chainId] && jsonContract.networks[chainId].address;

    if (!address) return [];

    const contract = await ethInstance.getContract("read", contracts.Multicall);
    const interf = getInterface(jsonAbi);

    const receiptDatas = events.map((event) => [event.poolId, event.receiptId]);

    const detailCalls = receiptDatas.map((receipt) => [
      address,
      interf.encodeFunctionData(functionName, receipt)
    ]);

    const rewardCalls = receiptDatas.map((receipt) => [
      address,
      interf.encodeFunctionData("getRewards", receipt)
    ]);

    const { returnData } = await contract.aggregate([...detailCalls, ...rewardCalls]);

    const part = Math.floor(returnData.length / 2);
    const [details, rewards] = [
      returnData.slice(0, part),
      returnData.slice(part, returnData.length)
    ];

    const decodedDetails = details.map((detail) => {
      const data = interf.decodeFunctionResult(functionName, detail);
      return parseEventArgs(data);
    });

    const decodedRewards = rewards.map((reward) => {
      const data = interf.decodeFunctionResult("getRewards", reward);
      return parseBNData(data, RESULT_TYPES.ARRAY, true);
    });

    return [decodedDetails, decodedRewards];
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
}

export async function getPositionDetails(
  pool,
  rewards,
  receipt,
  receiptId,
  event,
  getFromTime,
  getMaturityRewards
) {
  const { chainId, value, poolData, rewardData } = pool;
  const [rewardsCurrent, rewardsAdditional] = rewards;
  const depositAmount = receipt[0];
  const timeDeposited = receipt[1];
  const timeWithdrawn = receipt[2];

  const isActive = Number(timeWithdrawn) === 0;

  let lpToken = false;
  if (specialPools.lpBasic[chainId]?.includes(parseInt(value))) lpToken = true;

  let end = !isActive ? timeWithdrawn : null;
  const _calcBasicFromTime = await calcBasicFromTime(
    poolData,
    timeDeposited,
    rewardData,
    end,
    lpToken
  );

  const yieldAtMaturity = parseFloat(
    _calcBasicFromTime
    //uncomment when custom pool will be turned off
    //await getFromTime(timeDeposited, value, end, lpToken)
  );

  const deposit = parseFloat(fromWei(depositAmount));
  let rewardsAtMaturity = (yieldAtMaturity * deposit) / 100;

  if (lpToken) {
    rewardsAtMaturity = await getMaturityRewards(receiptId, value);
  }

  const { blockNumber, transactionHash, owner } = event;

  let position = {
    id: receiptId,
    txHash: transactionHash,
    blockNumber,
    deposit,
    rewardsAtMaturity,
    yieldAtMaturity,
    owner
  };

  if (isActive) {
    try {
      const currentRewards = parseFloat(fromWei(rewardsCurrent));
      let currentYield = (currentRewards * 100) / deposit;
      if (lpToken) {
        let now = Math.floor(moment.now() / 1000);
        currentYield = parseFloat(await getFromTime(timeDeposited, value, now, lpToken));
      }
      position = {
        ...position,
        currentRewards,
        currentYield,
        additionalRewards: rewardsAdditional ? fromWei(rewardsAdditional) : 0
      };
    } catch (error) {
      console.error("getting rewards fail: ", error);
      return;
    }
  }

  return { isActive, position };
}

export async function getCurrentBlockNumber() {
  return (await ethInstance.getBlock("latest")).number;
}

async function joinPositionData({
  events,
  receipts,
  pool,
  rewards,
  getFromTime,
  getMaturityRewards
}) {
  const numberOfPositions = receipts.length;

  const detailsPromises = [];

  for (let index = 0; index < numberOfPositions; index++) {
    const receipt = receipts[index];
    const event = events[index];
    const receiptId = event.receiptId.toString();
    const _rewards = rewards[index] ?? null;

    detailsPromises.push(
      getPositionDetails(pool, _rewards, receipt, receiptId, event, getFromTime, getMaturityRewards)
    );
  }

  return await Promise.all(detailsPromises);
}

export async function getPositionsV2(
  pool,
  eventName,
  getFromTime,
  getMaturityRewards,
  filters = null
) {
  if (!pool.value) return {};

  const isWithdrawalsEvents = eventName === "WithdrawalOccurred";
  const allEvents = await getEventsFromCache(pool, eventName, filters);
  if (!allEvents.length) return { activePositions: {}, inActivePositions: {} };
  if (isWithdrawalsEvents) {
    let withTransactions = {};
    for (const event of allEvents) {
      withTransactions = { ...withTransactions, [event.data.receiptId]: event.transactionHash };
    }
    return withTransactions;
  }

  const events = allEvents.map((event) => {
    const { data, transactionHash, blockNumber } = event;
    return { ...data, transactionHash, blockNumber };
  });

  const functionName = "getReceipt";

  const [receipts, rewards] = await getPositionsDataMulti(events, pool, functionName);

  let params = {
    events,
    receipts,
    rewards,
    pool,
    getFromTime,
    getMaturityRewards
  };

  const joinedPositionData = await joinPositionData({ ...params });

  const positions = {
    activePositions: Object.assign(
      {},
      joinedPositionData.filter((x) => x.isActive).map((y) => y.position)
    ),

    inActivePositions: Object.assign(
      {},
      joinedPositionData.filter((x) => !x.isActive).map((y) => y.position)
    )
  };

  return positions;
}

export async function getEventsFromCache(poolInstance, eventName, customQuery = null) {
  if (!poolInstance) return;

  try {
    const { chainId, contract, value } = poolInstance;
    const events = await getAllEvents(chainId, contract, eventName, { poolId: value }, customQuery);
    return events;
  } catch (error) {
    console.log(`Error while getting position for pool ${poolInstance.value}`, error);
  }
}

export async function approveBasicPoolByType(poolType, amount, poolIndex) {
  const [yieldContract, tokenContract, account] = await Promise.all([
    getWriteContractByType(poolType),
    getDepositToken(poolType, poolIndex),
    ethInstance.getEthAccount()
  ]);

  const currentApproval = parseFloat(
    fromWei(await tokenContract.allowance(account, yieldContract.address))
  );

  if (currentApproval < parseFloat(amount)) {
    const approvalAmount = process.env.REACT_APP_BASIC_POOL_APPROVAL_AMOUNT ?? "1000000";
    return await tokenContract.approve(yieldContract.address, toWei(approvalAmount));
  }
}

export async function withdrawBasicPoolByType(poolType, receiptId, poolIndex) {
  const yieldContract = await getWriteContractByType(poolType);
  return await yieldContract.withdraw(poolIndex, receiptId);
}

export async function depositBasicByType(poolType, poolIndex, amount) {
  const [yieldContract, tokenContract, account] = await Promise.all([
    getWriteContractByType(poolType),
    getDepositToken(poolType, poolIndex),
    ethInstance.getEthAccount()
  ]);

  const depositAmount = toWei(amount);
  const currentApproval = parseFloat(
    fromWei(await tokenContract.allowance(account, yieldContract.address))
  );

  if (currentApproval < parseFloat(amount)) {
    const approvalAmount = process.env.REACT_APP_BASIC_POOL_APPROVAL_AMOUNT ?? "1000000";
    await tokenContract.approve(yieldContract.address, toWei(approvalAmount));
  }

  return await yieldContract.deposit(poolIndex, depositAmount);
}
