import { bytesToString, ethInstance, fromWei, stringToBytes } from "evm-chain-scripts";
import moment from "moment";
import specialPools from "specialPools.json";
import {
  getDepositToken,
  getPoolInstance,
  getReceiptByType,
  getWriteContractByType,
  inputToArray
} from "./helper";
import {
  BASIC_CONTRACT_TYPE,
  calcBasicFromTime,
  getBytes,
  getRewardTokenPerLPToken,
  getTokenSymbol,
  secondsPerYear
} from "./poolHelper";

export async function getBasicMaturityRewards(receiptId, poolIndex) {
  const poolInstance = getPoolInstance(BASIC_CONTRACT_TYPE.BASIC);

  const yieldContract = await ethInstance.getContract("read", poolInstance.contract);

  const [pool, rewardData, receipt] = await Promise.all([
    yieldContract.pools(poolIndex),
    yieldContract.getRewardData(poolIndex), // 0-rewardsWeiPerSecondPerToken[], 1-rewardsWeiClaimed[], 2-rewardTokens[]
    getReceiptByType(BASIC_CONTRACT_TYPE.BASIC, receiptId, poolIndex)
  ]);

  const timeSecDiff = pool.endTime - receipt[1];
  const rewardsWeiPerSecondPerToken = fromWei(rewardData[0][0]);
  const depositAmount = fromWei(receipt[0]);
  const rewardAtMaturity = timeSecDiff * rewardsWeiPerSecondPerToken * depositAmount;
  return rewardAtMaturity;
}

export async function getBasicFromTime(startTime, poolIndex, end = null, lpToken = false) {
  const poolInstance = getPoolInstance(BASIC_CONTRACT_TYPE.BASIC);
  const yieldContract = await ethInstance.getContract("read", poolInstance.contract);

  const [pool, rewardsData] = await Promise.all([
    yieldContract.pools(poolIndex),
    yieldContract.getRewardData(poolIndex) // 0-rewardsWeiPerSecondPerToken[], 1-rewardsWeiClaimed[], 2-rewardTokens[]
  ]);

  const tokensPerToken = await calcBasicFromTime(pool, startTime, rewardsData, end, lpToken);
  return tokensPerToken.toString();
}

export async function getBasicPoolMaxNumber() {
  const yieldContract = await getWriteContractByType(BASIC_CONTRACT_TYPE.BASIC);
  return await yieldContract.numPools();
}

export async function getBasicAccountBalance(poolIndex) {
  if (!poolIndex || poolIndex.startsWith("0x")) return;

  let balance = 0;
  try {
    const [tokenContract, account] = await Promise.all([
      getDepositToken(BASIC_CONTRACT_TYPE.BASIC, poolIndex),
      ethInstance.getEthAccount(false)
    ]);
    balance = fromWei(await tokenContract.balanceOf(account));
  } catch (error) {
    console.log("Error while getting account balance:", error);
  }

  return balance;
}

export async function getAllBasicTokensSymbols(poolIndex) {
  const poolInstance = getPoolInstance(BASIC_CONTRACT_TYPE.BASIC);

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const [pool, rewardData] = await Promise.all([
    contract.pools(poolIndex),
    contract.getRewardData(poolIndex)
  ]);

  const reward1Token = rewardData[2][0];
  const reward2Token = rewardData[2].length > 1 ? rewardData[2][1] : null;

  const [depositTokenSymbol, reward1TokenSymbol, reward2TokenSymbol] = await Promise.all([
    getTokenSymbol(pool.depositToken),
    reward1Token ? getTokenSymbol(reward1Token) : "",
    reward2Token ? getTokenSymbol(reward2Token) : ""
  ]);

  return {
    depositTokenSymbol: depositTokenSymbol,
    reward1TokenSymbol: reward1TokenSymbol,
    reward2TokenSymbol: reward2TokenSymbol
  };
}

export async function getBasicGlobals(poolIndex, lpToken = false) {
  if (!poolIndex) return;

  const poolInstance = getPoolInstance(BASIC_CONTRACT_TYPE.BASIC);
  try {
    const contract = await ethInstance.getContract("read", poolInstance.contract);

    const [pool, rewardData, poolMetadata, chainId] = await Promise.all([
      contract.pools(poolIndex),
      contract.getRewardData(poolIndex), // 0-rewardsWeiPerSecondPerToken[], 1-rewardsWeiClaimed[], 2-rewardTokens[]
      contract.metadatas(poolIndex),
      ethInstance.getChainId()
    ]);

    if (specialPools.lpBasic[chainId]?.includes(parseInt(poolIndex))) lpToken = true;

    const tokensPerSecondPerToken = fromWei(rewardData[0][0] ?? "0");
    // TODO: don't assume first reward token is same as deposit token
    const maxRewards = tokensPerSecondPerToken * secondsPerYear;

    let now = Math.floor(moment.now() / 1000);

    let yldCurrent = 0;

    if (now <= pool.endTime) {
      if (now < pool.startTime) {
        now = pool.startTime;
      }
      const yld = await calcBasicFromTime(pool, now, rewardData, null, lpToken);

      yldCurrent = parseFloat(yld);
    }

    let apy = maxRewards * 100;

    if (lpToken) {
      const rewardsPerLP = await getRewardTokenPerLPToken(pool.depositToken, rewardData[2][0]);
      apy /= fromWei(rewardsPerLP.toString());
    }

    const isFinished = pool.endTime <= Math.floor(Date.now() / 1e3);

    return {
      maxPoolSize: fromWei(pool.maximumDepositWei),
      literalAPY: apy,
      basicTVL: parseFloat(fromWei(pool.totalDepositsWei)),
      dates: { startTime: pool.startTime, endTime: pool.endTime },
      currentYield: yldCurrent,
      name: `${bytesToString(poolMetadata.name)} Pool${isFinished ? " (Finished)" : ""}`,
      depositToken: pool.depositToken
    };
  } catch (error) {
    console.log("Error while getting basic pool", error);
  }
}

export async function getBasicGlobalsAll(light = false) {
  try {
    const poolInstance = getPoolInstance(BASIC_CONTRACT_TYPE.BASIC);
    const { chainId } = poolInstance;

    if (
      !poolInstance.contract.networks[chainId] ||
      !poolInstance.contract.networks[chainId].address
    ) {
      return [];
    }

    const contract = await ethInstance.getContract("read", poolInstance.contract);
    const poolIndexMax = await contract.numPools();
    const items = [];
    for (let index = 1; index <= poolIndexMax; index++) {
      if (specialPools.ignoredBasic[chainId]?.includes(index)) continue;

      let lpToken = false;
      if (specialPools.lpBasic[chainId]?.includes(index)) lpToken = true;

      const [pool, poolMetadata, rewardData] = await Promise.all([
        contract.pools(index),
        contract.metadatas(index),
        contract.getRewardData(index)
      ]);

      const isFinished = pool.endTime <= Math.floor(Date.now() / 1e3);

      if (light) {
        items.push({
          index: index,
          maxPoolSize: fromWei(pool.maximumDepositWei),
          basicTVL: parseFloat(fromWei(pool.totalDepositsWei)),
          dates: { startTime: pool.startTime, endTime: pool.endTime },
          depositToken: pool.depositToken,
          name: `${bytesToString(poolMetadata.name)}${isFinished ? " (Finished)" : ""}`
        });
      } else {
        const tokensPerSecondPerToken = fromWei(rewardData[0][0]);
        const maxRewards = tokensPerSecondPerToken * secondsPerYear;

        let now = Math.floor(moment.now() / 1000);
        let yldCurrent = 0;

        if (now <= pool.endTime) {
          if (now < pool.startTime) {
            now = pool.startTime;
          }
          const yld = await calcBasicFromTime(pool, now, rewardData, null, lpToken);

          yldCurrent = parseFloat(yld);
        }

        let apy = maxRewards * 100;

        if (lpToken) {
          const rewardsPerLP = await getRewardTokenPerLPToken(pool.depositToken, rewardData[2][0]);
          apy /= fromWei(rewardsPerLP.toString());
        }

        items.push({
          index: index,
          maxPoolSize: fromWei(pool.maximumDepositWei),
          literalAPY: apy,
          basicTVL: parseFloat(fromWei(pool.totalDepositsWei)),
          dates: { startTime: pool.startTime, endTime: pool.endTime },
          currentYield: yldCurrent,
          depositToken: pool.depositToken,
          name: `${bytesToString(poolMetadata.name)}${isFinished ? " (Finished)" : ""}`
        });
      }
    }

    return items;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function addBasicPool(pool) {
  const basicPool = await getWriteContractByType(BASIC_CONTRACT_TYPE.BASIC);

  const rewardsWeiPerSecondPerToken = Array.isArray(pool.rewardsWeiPerSecondPerToken)
    ? pool.rewardsWeiPerSecondPerToken
    : inputToArray(pool.rewardsWeiPerSecondPerToken);

  const rewardTokenAddresses = Array.isArray(pool.rewardTokenAddresses)
    ? pool.rewardTokenAddresses
    : inputToArray(pool.rewardTokenAddresses);

  return await basicPool.addPool(
    pool.startTime,
    pool.maxDeposit,
    rewardsWeiPerSecondPerToken,
    pool.programLengthDays,
    pool.depositTokenAddress,
    rewardTokenAddresses,
    getBytes(pool.ipfsHash),
    stringToBytes(pool.name)
  );
}
