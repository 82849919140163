import moment from "moment";
import numeral from "numeral";

export const formatTimeToDateShort = (time, separator = "-") =>
  moment(time * 1000).format(`DD${separator}MM${separator}YYYY`);

export const formatNumber = (number, precision = 2) => {
  if (number === "∞") {
    return "∞";
  }
  const formattedNumber = numeral(number).format("0,0.[".concat("0".repeat(precision), "]"));

  return formattedNumber;
};

export const formatAddress = (address) => {
  if (address) {
    const formattedAddress = address.slice(0, 6) + "..." + address.slice(-4);

    return formattedAddress;
  } else {
    return "";
  }
};
